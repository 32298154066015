import React from "react";
import Layout from "../components/layout";
import Proj from "../components/proj";
import { motion as m } from "framer-motion";
import * as styles from "../components/index.module.css"

let api = "https://api.nasa.gov/planetary/apod?api_key=OBjDHE3atfgZm3DyHrrsGl49QVpKjlxTqfrjoI9N";
function getNasa() {
  fetch(api)
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      document.getElementById("nasa").innerHTML = data.explanation;
      document.getElementById("nasaImg").src = data.url;
    });
}
//testing something
const Contact = () => (
    <m.div  initial={{ y: 100, opacity: 0 }}
  animate={{ y:0,opacity: 1 }}
  transition={{ duration: 1, ease: "easeOut" }}>
  <Layout>
  <h3>
  Send a message or email me at any of these links, and I'll get back to you as soon as possible!
  Looking forward to connecting with you!
        <br/>
        <br/>
        <p > <Proj/> </p>
        </h3>
        <section className={styles.bubble}>
         </section>
        <br/>
        <br/>
        <br/>
        <br/>
        <div id={styles["nasa"]}>
        <button onClick={getNasa}>Click for NASA's Astronomy Picture of the Day</button>
        <img src="" alt="Image Of the Day" id="nasaImg"/>
        <p id="nasa"></p>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        </Layout>
        </m.div>
)

export default Contact;